
import Vue, { PropType } from 'vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import { Fonts as fontsEnum } from '@/application/enums/fonts';
import KSelect from '@/components/crud/fields/KSelect.vue';
import EntityOverwriteAlert from '@/modules/companyEntity/components/EntityOverwriteAlert.vue';
import { translateField } from '@/application/util/translation';
import CompanyEntity from '@/application/models/CompanyEntity';
import validIban from '@/application/util/inputFieldRules/validIban';

interface Panel {
  name: string
}

interface ComponentData {
  panels: Panel[]
}

export default Vue.extend({
  name: 'TradeNameForm',
  components: {
    EntityOverwriteAlert,
    KSelect,
    KFileField,
    KTextField,
    KFormDialog,
    KFieldGroup,
  },
  props: {
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
    },
    parentEntity: {
      type: Object as PropType<CompanyEntity>,
      required: true,
    },
  },
  data: (): ComponentData => ({
    panels: [
      { name: translateField('default', 'tradeNames.tabs') },
      { name: translateField('financial', 'tradeNames.tabs') },
      { name: translateField('documentLayout', 'tradeNames.tabs') },
    ],
  }),
  computed: {
    fonts(): string[] {
      return Object.values(fontsEnum);
    },
    parentEntitytemporaryWorkerCostPriceFactor(): string {
      const temporaryWorkerCostPriceFactor = this.parentEntity.temporaryWorkerCostPriceFactor ?? 1;
      return this.$n(temporaryWorkerCostPriceFactor / 10000, 'factor');
    },
  },
  methods: {
    validIban,
    backgroundImageSizeRestriction(value: HTMLInputElement) {
      return !value || value.size < 1000000 || this.$t('errors.fileTooLarge', { size: '1MB' });
    },
  },
});
