// @ts-ignore @todo, convert api implementation into typescript
import { get, post, put } from '@/application/api/implementations/app';
// @ts-ignore @todo, convert api implementation into typescript
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';
import TradeName from '@/application/models/TradeName';
import { AxiosPromise } from 'axios';

export const show = (tradeNameId: number): AxiosPromise => get(`trade-name/${tradeNameId}`);
export const update = (tradeName: TradeName): AxiosPromise => put(`trade-name/${tradeName.id}`, tradeName);

export const index = (entityId: number, page: number, perPage: number, search: string, sortBy: string, descending: boolean, params: Record<string, undefined>): AxiosPromise => {
  return getPaginated(`entity/${entityId}/trade-name`, page, perPage, search, sortBy, descending, params);
};

export const create = (entityId: number, tradeName: TradeName): AxiosPromise => {
  return post(`entity/${entityId}/trade-name`, tradeName);
};
