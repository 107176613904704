import Model from '@/application/models/ts/model';
import DocumentLayout from '@/application/models/DocumentLayout';
import mapFileForRequest from '@/application/util/mapFileForRequest';

class TradeName extends Model {
  id = 0;
  name = '';
  vatNumber = '';
  email = '';
  street = '';
  houseNumber = '';
  zipCode = '';
  city = '';
  phone = '';
  iban = '';
  ibanAscription = '';
  bicCode = '';
  temporaryWorkerCostPriceFactor = null;
  logo = null;
  documentLayout = new DocumentLayout();

  mapForRequest(): Record<string, any> {
    return {
      ...this,
      logo: mapFileForRequest(this.logo),
      temporaryWorkerCostPriceFactor: Math.round((this.temporaryWorkerCostPriceFactor ?? 1) * 10000),
      documentLayout: this.documentLayout.mapForRequest(),
    };
  }

  mapResponse(data: Record<string, any>): Record<string, any> {
    return super.mapResponse({
      ...data,
      temporaryWorkerCostPriceFactor: data.temporaryWorkerCostPriceFactor / 10000,
    });
  }
}

export default TradeName;
