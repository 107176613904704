
import Vue from 'vue';
import CompanyEntity from '@/application/models/CompanyEntity';
import TradeName from '@/application/models/TradeName.ts';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import { index, create, update, show } from '@/modules/companyEntity/api/tradeNames.ts';
import { show as showEntity } from '@/modules/companyEntity/api/companyEntities.ts';
import { DataTableHeader } from 'vuetify/types';
import eventBus from '@/application/eventBus.ts';

import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import TradeNameForm from '@/modules/companyEntity/components/TradeNameForm.vue';
import { translateField } from '@/application/util/translation';
import { AxiosPromise } from 'axios';

interface ComponentData {
  searchQuery: string,
  createDialog: boolean,
  updateDialog: boolean,
  createFormValues: TradeName,
  updateFormValues: TradeName,
  parentEntity: CompanyEntity,
}

interface Header extends Omit<DataTableHeader, 'text'> {
  text?: string,
  language?: string
}

export default Vue.extend({
  name: 'TradeNames',
  components: {
    KCrudTable,
    TradeNameForm,
    KCrudLayout,
  },
  data: (): ComponentData => ({
    searchQuery: '',
    createDialog: false,
    updateDialog: false,
    createFormValues: new TradeName(),
    updateFormValues: new TradeName(),
    parentEntity: new CompanyEntity(),
  }),
  computed: {
    crudHeaders(): Header[] {
      return [
        {
          value: 'name',
          text: translateField('name', 'tradeNames.fields'),
        },
      ];
    },
  },
  async created() {
    const response = await showEntity(parseInt(this.$route.params.entityId));
    this.parentEntity = response.data.data;
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        // todo: set route to companyEntity
        to: { name: 'settings.company-entities' },
        // @ts-ignore @todo, create typescript function for $tc
        text: this.$tc('entity.title', 2),
      },
      {
        exact: true,
        to: { name: this.$route.name },
        text: this.parentEntity.name,
      },
    ]);
  },
  methods: {
    indexRequest(...args: any[]) {
      // @ts-ignore @todo: fix ...args
      return index(this.$route.params.entityId, ...args);
    },
    createRequest() {
      return create(this.parentEntity.id, this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    handleOpenCreateDialog(): void {
      this.createFormValues = new TradeName();
      this.createDialog = true;
    },
    async handleOpenUpdate(item: TradeName) {
      this.updateFormValues = new TradeName();
      const response = await show(item.id);
      this.updateFormValues.mapResponse(response.data.data);
      this.updateDialog = true;
    },
  },
});
