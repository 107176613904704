import Model from '@/application/models/ts/model';
import mapFileForRequest from '@/application/util/mapFileForRequest';

class DocumentLayout extends Model {
  font = '';
  invoiceCaption = '';
  background = null;
 
  mapForRequest():Record<string, any> {
    return {
      ...this,
      background: mapFileForRequest(this.background),
    };
  }

}

export default DocumentLayout;
